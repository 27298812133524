function LandingServices() {
  return (
    <div>
      <section class="bg-white dark:bg-gray-900">
        <div class="container px-6 py-10 mx-auto">
          <div class="lg:-mx-6 lg:flex lg:items-center">
            <img
              class="object-cover object-center lg:w-1/2 lg:mx-6 w-full h-96 rounded-lg lg:h-[36rem]"
              src="/Images/Homepage2.jpg"
              alt=""
            />

            <div class="mt-8 lg:w-1/2 lg:px-6 lg:mt-0">
              <h1 class="text-2xl font-semibold text-gray-800 dark:text-white lg:text-3xl lg:w-96">
                Our services
              </h1>
              <div class="mt-2">
                <span class="inline-block w-40 h-1 bg-customBlue rounded-full"></span>
                <span class="inline-block w-3 h-1 ml-1 bg-customBlue rounded-full"></span>
                <span class="inline-block w-1 h-1 ml-1 bg-customBlue rounded-full"></span>
              </div>
              <p class="max-w-lg mt-6 text-gray-500 dark:text-gray-400 ">
                We offer assurance in assisting you from the very first step of
                your journey through making processes simple, easy and hassle
                free. We help candidates to settle into the most appropriate
                role for their skill-set. We aim not only to help you in a
                professional capacity but will also provide assistance in your
                move to the UK, providing help and support to ensure you thrive
                in a new culture.  
                <br></br>
                <br></br>
                We at UK Medical Direct take our time to
                understand the needs of every individual, no matter how complex
                these maybe.
              </p>

              <h3 class="mt-6 text-lg font-medium text-customBlue hover:color:text-customBlue">
                <a href="./../applicantapply"> Apply now</a>
              </h3>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LandingServices;
