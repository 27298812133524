import Slider from "../Components/Slider";
import Service from "../Components/Service";

function Services() {
  return (
    <div>
      <Slider></Slider>

      <div className="px-20 mt-10 w-full" id="services">
        <div className="my-5 heading flex flex-col items-center text-center">
          <h1 className="text-2xl font-bold text-gray-800 dark:text-white lg:text-3xl lg:w-96">
            Our Services
          </h1>
          <div className="mt-2">
            <span className="inline-block w-40 h-1 bg-customBlue rounded-full"></span>
            <span className="inline-block w-3 h-1 ml-1 bg-customBlue rounded-full"></span>
            <span className="text-center inline-block w-1 h-1 ml-1 bg-customBlue rounded-full"></span>
          </div>
        </div>

        <Service
          title="Process for Healthcare Professionals"
          text="We can help you unlock your potential and secure your ideal role in the UK. 
When applying for a new position, it's widely recognized that submitting your CV through a certified recruitment agency enhances your professional image. It demonstrates your understanding of the recruitment process and your desire to be represented by a reputable agency with a prestigious client base."
          link="./../applicantapply"
          direction="ltr"
          imageSrc="/Images/Image3.png " 
        />
        <Service
          title="Are you a healthcare provider seeking skilled, competent, and dedicated Allied Health Professionals?"
          text="Look no further – UK Medical Direct is here to assist you."
          link="./../recruiterapply"
          direction="rtl"
          imageSrc="/Images/bigImage.png"
        />
      </div>
    </div>
  );
}

export default Services;
