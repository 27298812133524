function Service({ title, text, link, direction = 'ltr', imageSrc }) {
  const isRTL = direction === 'rtl';

  return (
    <div
      className={`my-20 relative flex flex-col-reverse py-16 lg:py-0 ${
        isRTL ? 'lg:flex-row-reverse' : 'lg:flex-row'
      }`}
    >
      <div
        className={`w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:py-20 lg:max-w-screen-xl ${
          isRTL ? 'lg:pl-8' : 'lg:pr-8'
        }`}
      >
        <div className="mb-0 lg:max-w-lg xl:pr-6">
          <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
            {title}
          </h2>
          <p className="mb-5 text-base text-gray-700 md:text-lg">{text}</p>
          <div className="mb-10 md:mb-16 lg:mb-20">
            <a
              href={link}
              className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-customBlue hover:bg-customBlue-light focus:shadow-outline focus:outline-none"
            >
              Apply Now
            </a>
          </div>
        </div>
      </div>
      <div
        className={`inset-y-0 top-0 ${
          isRTL
            ? 'left-0'
            : 'right-0 lg:w-1/2 lg:absolute'
        } w-full max-w-xl px-4 mx-auto mb-6 md:px-0 lg:${
          isRTL ? 'pl-0' : 'pr-0'
        } lg:mb-0 lg:mx-0 lg:max-w-full xl:px-0`}
      >
        <img
          className="object-cover w-full h-56 rounded-xl shadow-lg lg:shadow-none md:h-96 lg:h-full"
          src={imageSrc}
          alt={title}
        />
      </div>
    </div>
  );
}

export default Service;
