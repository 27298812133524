import Slider from '../Components/Slider';
import AboutComp from '../Components/AboutComp';

function About() {
    return (
        <div>
            <Slider>
            </Slider>
            <AboutComp>
                
            </AboutComp>
        </div>
    );
}

export default About;