import Slider from "../Components/Slider";
import LandingServices from "../Components/LandingServices";
import Heading from "../Components/Heading";

function Home() {
  return (
    <div>
      <Slider />
      <Heading
        text="About Us"
        subText="As experienced healthcare professionals ourselves, we possess unique insight and knowledge of the needs of both potential candidates and the healthcare services they will be working in. This allows us to successfully recruit health professionals and put the right people into the right roles. Through creating long-term relationships with our candidates and healthcare providers, we offer a personalised service that is tailored to your professional needs within every aspect of the recruitment process. This ensures you find a role that is perfect for you."
      >
        <h3 class="mt-6 text-lg font-medium text-customBlue hover:color:text-customBlue">
          <a href="/about"> Read More</a>
        </h3>
      </Heading>
      <LandingServices></LandingServices>
    </div>
  );
}

export default Home;
