const PersonalDetails = ({ formData, setFormData, setIsValid }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    
    // Validate all required fields after each change
    validateForm({ ...formData, [name]: value });
  };

  const validateForm = (data) => {
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email);
    const isValidPassword = data.password.length >= 6; // Assuming minimum 6 characters
    
    const isFormValid = 
      data.firstName.trim() !== '' &&
      data.lastName.trim() !== '' &&
      data.email.trim() !== '' &&
      isValidEmail &&
      data.password.trim() !== '' &&
      isValidPassword &&
      data.dateOfBirth !== '' &&
      data.address.trim() !== '';

    setIsValid(isFormValid);
  };

  return (
    <div>

      <h2 className="text-2xl font-bold mb-4">Personal Details</h2>
      <div className="space-y-4">
        <div>
          <label htmlFor="firstName" className="required block text-sm font-medium text-gray-700">First Name</label>
          <input
            type="text"
            id="firstName"
            required
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label htmlFor="lastName" className="required block text-sm font-medium text-gray-700">Last Name</label>
          <input
            type="text"
            id="lastName"
            required
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        {/*  */}
        <div>
          <label htmlFor="email" className="required block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            id="email"
            required
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>

        <div>
          <label htmlFor="password" className="required block text-sm font-medium text-gray-700">Password</label>
          <input
            type="password"
            id="password"
            required
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>


        {/*  */}
        <div>
          <label htmlFor="dateOfBirth" className="required block text-sm font-medium text-gray-700">Date of Birth</label>
          <input
            type="date"
            id="dateOfBirth"
            required
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>

        <div>
          <label htmlFor="address" className="required block text-sm font-medium text-gray-700">Address</label>
          <textarea
            id="address"
            name="address"
            required
            value={formData.address}
            onChange={handleChange}
            rows="4"
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
