import { useState, useRef } from 'react';
import { io } from 'socket.io-client';
import { jwtDecode } from 'jwt-decode';


const BASE_URL = process.env.REACT_APP_PRODUCTION_URL;

export const useSocketService = () => {
  const [socket, setSocket] = useState(null);
  const [userId, setUserId] = useState('');
  const socketRef = useRef(null);

  const getSocketId = () => {
    return socketRef.current?.id;
  };

  const initialize = (handlers) => {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        reject('No token found');
        return;
      }
  
      try {
        const decodedToken = jwtDecode(token);
        setUserId(decodedToken.id);
  
        const newSocket = io(BASE_URL, {
          auth: { token },
          reconnection: true,
          reconnectionAttempts: 5,
          reconnectionDelay: 1000,
          transports: ['polling', 'websocket'],
          withCredentials: false,
          forceNew: true,
        });
  
        // Set up event handlers
        newSocket.on('connect', () => {
          console.log('Socket connected successfully with ID:', newSocket.id);
          handlers.onConnect?.(newSocket.id);
          resolve(newSocket.id); // Resolve the promise when connected
        });
  
        newSocket.on('new_message', (message) => {
          handlers.onMessage?.(message);
        });
  
        newSocket.on('disconnect', () => {
          handlers.onDisconnect?.();
        });

        newSocket.on('new_notification', (notification) => {
          handlers.onNotification?.(notification);
        });
  
        setSocket(newSocket);
        socketRef.current = newSocket;
      } catch (error) {
        console.error('Socket initialization error:', error);
        reject(error);
      }
    });
  };
  

  const sendMessage = (message) => {
    if (socketRef.current) {
      console.log('Emitting message through socket:', message);
      socketRef.current.emit('send_message', message, (acknowledgment) => {
        console.log('Server acknowledged message:', acknowledgment);
      });
    }
  };

  const sendNotificationToUser = (userId, message) => {
    if (socketRef.current) {
      console.log(`Emitting message to user ${userId} through socket:`, message);
      socketRef.current.emit('send_notification_to_user', { userId, message }, (acknowledgment) => {
        console.log('Server acknowledged Notification:', acknowledgment);
      });
    } else {
      console.log('Socket is not initialized');
    }
  };

  const sendNotification = (notification) => {
    if (socketRef.current) {
      console.log('Emitting notification through socket:', notification);
      socketRef.current.emit('send_notification', notification, (acknowledgment) => {
        console.log('Server acknowledged notification:', acknowledgment);
      });
    }
  };

  const disconnect = () => {
    if (socketRef.current) {
      socketRef.current.disconnect();
      setSocket(null);
      socketRef.current = null;
    }
  };

  return { initialize, sendMessage, sendNotificationToUser, sendNotification, disconnect, userId, getSocketId };
};



