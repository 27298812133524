import React, { useState, useEffect, useRef } from "react";
import logo from "../logo.png";
import LoginModal from "./Modals/LoginModal";
// import { useSocketService } from "../utils/socket";

function NavBar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dropdownRef = useRef(null);
  // const socketService = useSocketService();

  const toggleDropdown = (event) => {
    event.stopPropagation();
    setIsDropdownOpen((prev) => !prev);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('currentLoggedInUser');
    localStorage.removeItem('LoggedInUserRole');
    setIsLoggedIn(false);
    window.location.href = "/";
  };

  useEffect(() => {
    // socketService.initialize({  
    //   onConnect: () => {
    //     console.log("Socket connected successfully");
    //   },
    //   onMessage: (message) => {
    //     console.log("New message received:", message);
    //   },
    //   onDisconnect: () => {
    //     console.log("Socket disconnected");
    //   },
    // });

    const accessToken = localStorage.getItem("accessToken");
    setIsLoggedIn(accessToken !== null);

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const currentPath = window.location.pathname;
  const navBarClass =
    currentPath === "/"
      ? "shadow-md fixed z-20 w-full bg-white md:absolute md:bg-transparent"
      : "shadow-md z-20 w-full bg-white";

  return (
    <nav className={navBarClass} role="navigation" aria-label="Main Navigation">
      <div className="container m-auto px-4 md:px-12 lg:px-7">
        <div className="flex items-center justify-between py-3 md:py-4">
          {/* Logo Section */}
          <a
            href="/"
            aria-label="Home"
            className="flex items-center space-x-2"
          >
            <img src={logo} className="w-32 md:w-40" alt="UK Medical Logo" />
          </a>

          {/* Mobile Hamburger Menu */}
          <button
            className="block lg:hidden text-gray-700 focus:outline-none"
            onClick={toggleMobileMenu}
            aria-label="Toggle navigation menu"
          >
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>

          {/* Desktop Menu */}
          <div className="hidden lg:flex items-center space-x-8">
            <ul className="flex space-x-6 text-gray-600">
              <li>
                <a href="/" className="hover:text-customBlue-dark">
                  Home
                </a>
              </li>
              <li>
                <a href="/about" className="hover:text-customBlue-dark">
                  About
                </a>
              </li>
              <li>
                <a href="/services" className="hover:text-customBlue-dark">
                  Services
                </a>
              </li>
              <li>
                <a href="/contact" className="hover:text-customBlue-dark">
                  Contact
                </a>
              </li>
            </ul>
            {!isLoggedIn && (
              <div className="relative">
                <button
                  type="button"
                  onClick={toggleDropdown}
                  className="py-2 px-4 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 focus:outline-none"
                >
                  Sign Up
                </button>
                {isDropdownOpen && (
                  <div
                    id="dropdownMenu"
                    ref={dropdownRef}
                    className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-50 transition-all duration-200 ease-in-out transform scale-95 opacity-100 animate-fade-in"
                  >
                    <a
                      href="/applicantapply"
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Applicant
                    </a>
                    <a
                      href="/recruiterapply"
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Recruiter
                    </a>
                  </div>
                )}
              </div>
            )}
            {isLoggedIn && (
              <button
                className="py-2 px-4 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
                onClick={handleLogout}
              >
                Log out
              </button>
            )}
            <button
              className="py-2 px-4 bg-customBlue text-white rounded-lg hover:bg-customBlue-light"
              onClick={() => {
                if (isLoggedIn) {
                  window.location.href = "/template";
                } else {
                  toggleModal();
                }
              }}
            >
              {isLoggedIn ? "Dashboard" : "Login"}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="lg:hidden mt-2 bg-white border border-gray-200 rounded-lg shadow-lg">
            <ul className="space-y-4 p-4">
              <li>
                <a
                  href="/"
                  className="block text-gray-700 hover:text-customBlue-dark"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/about"
                  className="block text-gray-700 hover:text-customBlue-dark"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="/services"
                  className="block text-gray-700 hover:text-customBlue-dark"
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  className="block text-gray-700 hover:text-customBlue-dark"
                >
                  Contact
                </a>
              </li>
              <li>
                <button
                  onClick={toggleDropdown}
                  className="block text-gray-700 hover:text-customBlue-dark"
                >
                  Sign Up
                </button>
                {isDropdownOpen && (
                  <div
                    ref={dropdownRef}
                    className="mt-2 bg-white border border-gray-300 rounded-lg shadow-lg"
                  >
                    <a
                      href="/applicantapply"
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Applicant
                    </a>
                    <a
                      href="/recruiterapply"
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Recruiter
                    </a>
                  </div>
                )}
              </li>
              <li>
                <button
                  onClick={toggleModal}
                  className="w-full text-white bg-customBlue py-2 px-4 rounded-lg hover:bg-customBlue-light"
                >
                  Login
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>

      {/* Login Modal */}
      {isModalOpen && <LoginModal closeModal={toggleModal} />}
    </nav>
  );
}

export default NavBar;
