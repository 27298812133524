function AboutComp() {
  return (
    <div>
      <section className="bg-white dark:bg-gray-900">
        <div className="container px-6 py-10 mx-auto">
          <div>
            <div className="mt-4">
              {/* Mission Statement Section */}
              <div className="flex items-center space-x-4 mb-2">
                <img
                  src="/Images/icon1.png"
                  alt="Mission Icon"
                  className="w-8 h-8"
                />
                <h2 className="text-lg font-semibold text-gray-800 dark:text-white lg:text-lg lg:w-96">
                  Mission Statement
                </h2>
              </div>
              <p className="text-gray-600">
                UK Medical Direct is a unique professional, diverse and friendly
                recruitment organisation, with you at the heart of our services.
                We endeavour to deliver the highest level of service to both
                healthcare organisations and candidates seeking employment
                opportunities, therefore offering the best in international
                healthcare recruitment.
                <br />
                <br />
                Our people are the key to achieving this goal. With over 40
                years of UK health care experience between us, our dedicated
                team know what it takes to attract and inspire the very best
                talent in international healthcare recruitment. Our candidates
                will come to organisations prepared for the UK working
                environment, having undergone rigorous specialist training and
                education to ensure they possess the key skills required for
                successful integration into UK healthcare employment. Additional
                candidate support will also be provided to ensure a smooth
                transition into life in the United Kingdom through cultural
                integration with important aspects of living in the United
                Kingdom.
              </p>

              {/* Values Section */}
              <div className="flex items-center space-x-4 mt-6 mb-2">
                <img
                  src="/Images/icon2.png"
                  alt="Values Icon"
                  className="w-8 h-8"
                />
                <h2 className="text-lg font-semibold text-gray-800 dark:text-white lg:text-lg lg:w-96">
                  Values
                </h2>
              </div>
              <p className="text-gray-600">
                UK Medical Direct is committed to ensuring ethical recruitment.
                We do support and facilitate passage to the UK for direct
                applicants from the WHO list, where candidates have already been
                appointed or offered employment by an NHS provider. We pride
                ourselves on outstanding candidate support. From day one we
                provide all the guidance needed to prepare for exams, submit
                visa applications and get ready for your journey to the UK.
              </p>

              {/* Quality You Can Trust Section */}
              <div className="flex items-center space-x-4 mt-6 mb-2">
                <img
                  src="/Images/icon3.png"
                  alt="Quality Icon"
                  className="w-8 h-8"
                />
                <h2 className="text-lg font-semibold text-gray-800 dark:text-white lg:text-lg lg:w-96">
                  Quality you can trust
                </h2>
              </div>
              <p className="text-gray-600">
                Pre-screening is extremely rigorous with strict compliance
                checks in line with NHS standards. We focus on high-quality
                applicants with the highest levels of experience and language
                skills.
                <br></br>
                <br></br>
                Candidates are trained utilising evidence-based practice,
                reflection and skills-based competencies to guarantee that every
                candidate will be current in safe practices, giving you and them
                assurance that they will be providing the best in patient
                centred quality healthcare.
              </p>

              {/* Why Use Us Section */}
              <div className="flex items-center space-x-4 mt-6 mb-2">
                <img
                  src="/Images/icon4.png"
                  alt="Why Us Icon"
                  className="w-8 h-8"
                />
                <h2 className="text-lg font-semibold text-gray-800 dark:text-white lg:text-lg lg:w-96">
                  Why use us
                </h2>
              </div>
              <p className="text-gray-600">
                Positive outcomes – We ensure candidates are supported from day
                one, for as long as required to ensure happy and quality work
                environments now and in the future.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutComp;
