function Heading({text, subText="",children}) {

    return (
        <div>
            <div className="my-5 heading flex flex-col items-center text-center">
                <h1 className="text-2xl font-bold text-gray-800 dark:text-white lg:text-3xl">{text}</h1>
                <div className="mt-2"><span className="inline-block w-40 h-1 bg-customBlue rounded-full"></span><span className="inline-block w-3 h-1 ml-1 bg-customBlue rounded-full"></span><span className="text-center inline-block w-1 h-1 ml-1 bg-customBlue rounded-full"></span></div>
                <p className="max-w-5xl mt-6 text-gray-500 dark:text-gray-400 ">{subText}</p>
                <div>
                    {children}
                </div>
            </div>
        </div>
    )
}


export default Heading;