import React, { useState } from 'react';
import ImmigrationStatus from './Steps/ImmigrationStatus'
import PersonalDetails from './Steps/PersonalDetails';
import ProfessionalDetails from './Steps/ProfessionalDetails';
import EducationalDetails from './Steps/EducationalDetails';
import EmploymentHistory from './Steps/EmploymentHistory';
import SupportingInformation from './Steps/SupportingInformation';

import "./MultiStepForm.css"
import ToastNotification from './Steps/ToastNotification';
import { useSocketService } from '../../utils/socket';

const BASE_URL = process.env.REACT_APP_PRODUCTION_URL;
function MultiStepForm() {
    const [currentStep, setCurrentStep] = useState(1);
    const [isValid, setIsValid] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        email: '',
        password: '',
        lastName: '',
        dateOfBirth: '',
        address: '',
        gender: '',
        immigration_status: '',
        permit_details: '',
        hcpcNumber: '',
        hcpcExpiryDate: '',
        otherProfessionalRegistration: '',
        qualification0: '',
        placeOfStudy0: '',
        yearObtained0: '',
        result0: '',
        qualification1: '',
        placeOfStudy1: '',
        yearObtained1: '',
        result1: '',
        qualification2: '',
        placeOfStudy2: '',
        yearObtained2: '',
        result2: '',
        qualification3: '',
        placeOfStudy3: '',
        yearObtained3: '',
        result3: '',
        qualification4: '',
        placeOfStudy4: '',
        yearObtained4: '',
        result4: '',
        qualification5: '',
        placeOfStudy5: '',
        yearObtained5: '',
        result5: '',
        relevantTrainingCourses: '',
        employer0: '',
        employer1: '',
        employer2: '',
        employer3: '',
        employer4: '',
        personalStatement: ''
    });

    const socketService = useSocketService();

    const nextStep = () => {
        // Check validation for ImmigrationStatus (step 1)
        if (currentStep === 1 && !formData.immigration_status) {
            alert('Please select your immigration status');
            return;
        }
        // Existing validation for PersonalDetails
        if (currentStep === 2 && !isValid) {
            alert('Please fill out all required fields in Personal Details');
            return;
        }
        setCurrentStep((prev) => prev + 1);
    };
    
    const prevStep = () => setCurrentStep((prev) => prev - 1);

    const initializeSocket = async () => {
        console.log("Initializing Socket");
        try {
          const socketId = await socketService.initialize({
            onConnect: (id) => {
              console.log('Connected to socket with ID:', id);
            },
            onNotification: (notification) => { 
              console.log('Received notification:', notification);
            }
          });
          console.log('Socket initialized with ID:', socketId);
          return socketId;
        } catch (error) {
          console.error('Failed to initialize socket:', error);
        }
      };

    const loginUser = async (email, password) => {
        const response = await fetch(`${BASE_URL}/api/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email: email,
            password: password
          })
        });
        const data = await response.json();
        if(response.ok){
          localStorage.setItem("accessToken", data.data);
          localStorage.setItem("refreshToken", data.data.refreshToken);
        }else{
          alert(data.message);
        }
      }

    const handleSubmit = async(e) => {
        e.preventDefault();
        console.log("Pressed",formData);
        console.log(process.env.REACT_APP_PRODUCTION_URL);
        const response = await fetch(`${BASE_URL}/api/applicant/apply`, { 
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
          })
          const responseData = await response.json();
          console.log("Response Data",responseData);
          if(responseData.success === true){
        // alert("Sign-Up Successful!");
        alert("Application Submitted Successfully!");
        await loginUser(formData.email, formData.password);
        const socketId = await initializeSocket();
        if (!socketId) {
          console.error('Socket not initialized properly');
          return;
        }
        const users = await fetch(`${BASE_URL}/api/users`,{
          method: "GET",
            headers: {
              "Content-Type": "application/json"
            },
          });
        const usersData = await users.json();
        const adminUsers = usersData.data.filter(user => user.role === "admin");
        
        // Wait for all notifications to be sent using Promise.all
        await Promise.all(adminUsers.map(admin => {
          return Promise.all([
            socketService.sendNotificationToUser(admin.id, "New Applicant Signed-Up"),
            saveNotificationAlert(admin.id)
          ]);
        }));

            nextStep();
        } else {
          alert(responseData.message);
        }
          
          
    };
    const saveNotificationAlert = async (userId) => {
      const response = await fetch(`${process.env.REACT_APP_PRODUCTION_URL}/api/notifications`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({toUserId: userId, message:"New Applicant Signed-Up"})
      });
    }

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return <ImmigrationStatus formData={formData} setFormData={setFormData} />;
            case 2:
                return <PersonalDetails 
                         formData={formData} 
                         setFormData={setFormData} 
                         setIsValid={setIsValid} 
                       />;
            case 3:
                return <ProfessionalDetails formData={formData} setFormData={setFormData} />;
            case 4:
                return <EducationalDetails formData={formData} setFormData={setFormData} />;
            case 5:
                return <EmploymentHistory formData={formData} setFormData={setFormData} />;
            case 6:
                return <SupportingInformation formData={formData} setFormData={setFormData} />;
            case 7:
                return <ToastNotification />;
            default:
                return null;
        }
    };

    return (
        <div className="max-w-3xl mx-auto mt-10">
            <form onSubmit={handleSubmit}>
                {renderStep()}
                <div className="flex justify-between mt-4">
                    {currentStep > 1 && (
                        <button type="button" className="bg-gray-500 text-white px-4 py-2 rounded" onClick={prevStep}>
                            Back
                        </button>
                    )}
                    {currentStep < 6 && (
                        <button 
                            type="button" 
                            className={`${!isValid && currentStep === 2 ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500'} text-white px-4 py-2 rounded`}
                            onClick={nextStep}
                            disabled={!isValid && currentStep === 2}
                        >
                            Next
                        </button>
                    )}
                    {currentStep === 6 && (
                        <button 
                            type="submit"  
                            className="bg-green-500 text-white px-4 py-2 rounded"
                        >
                            Submit
                        </button>
                    )}
                </div>
            </form>
        </div>
    )
}

export default MultiStepForm